import { Urls } from "src/app/globals/urls";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { IProductIdentifier } from "../contracts/product-identifier.interface";

@Injectable({
    providedIn: "root"
})

export class ProductHttpService {
    constructor(private http: HttpClient) { }

    public async getProducts(): Promise<IProductIdentifier[]> {
        return await firstValueFrom(this.http.get<IProductIdentifier[]>(Urls.PRODUCTS));
    }

    public getEnabledProducts(): Observable<IProductIdentifier[]> {
        return this.http.get<IProductIdentifier[]>(Urls.ENABLED_PRODUCTS);
    }

    public updateProduct(product: IProductIdentifier): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = product;
        return this.http.post<boolean>(Urls.PRODUCTS, body, { headers: requestHeaders });
    }
}
